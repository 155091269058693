import React from "react";
import { List } from "@material-ui/core";

import { ShopIcon } from "../icons/ShopIcon";
import { NewsIcon } from "../icons/NewsIcon";
import { BonusIcon } from "../icons/BonusIcon";
import { Routes } from "../../constants/Routes";
import { ClientsIcon } from "../icons/ClientsIcon";
import { EmployeeIcon } from "../icons/EmployeeIcon";
import { AnalyticsIcon } from "../icons/AnalyticsIcon";
import { TransactionIcon } from "../icons/TransactionIcon";
import { AppMenuItemWrapper } from "./AppMenuItemWrapper";

export function AppMenu() {
  return (
    <List component="nav">
      <AppMenuItemWrapper title="Аналитика" to={Routes.Analytics} leftIcon={<AnalyticsIcon />} />
      <AppMenuItemWrapper title="Заведения" to={Routes.Shops} leftIcon={<ShopIcon />} />
      <AppMenuItemWrapper
        leftIcon={<BonusIcon />}
        to={Routes.LoyaltyPrograms}
        title="Программы лояльности"
      />
      <AppMenuItemWrapper title="Сотрудники" to={Routes.Employees} leftIcon={<EmployeeIcon />} />
      <AppMenuItemWrapper title="Клиенты" to={Routes.Clients} leftIcon={<ClientsIcon />} />
      <AppMenuItemWrapper
        title="Транзакции"
        to={Routes.Transactions}
        leftIcon={<TransactionIcon />}
      />
      <AppMenuItemWrapper title="Новости" to={Routes.News} leftIcon={<NewsIcon />} />
      {/*<AppMenuItemWrapper title="Акции" to={Routes.Promotions} leftIcon={<PromotionIcon />} />
      <AppMenuItemWrapper title="Документы" to={Routes.Documents} leftIcon={<DocumentIcon />} />*/}
    </List>
  );
}
